import React, { Suspense, useEffect, useState, lazy, useContext } from 'react';
import { notification, Spin, Progress, message, Button } from 'antd';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import Dashboard from './dashboard';
import DashVendas from './dashvendas';
import DashLocacao from './dashlocacao';
import DashCorretor from './dashcorretor';
import DashTemporada from './dashboard-temporada';
import ListaDeImoveis from './lista-de-imoveis';
import ListaDeClientes from './lista-de-clientes';
import HistoricoAtividades from './historico-atividades';
import Reservas from './reservas';
import Configs from './configs';
import Equipe from './equipe';
import Equipes from './equipes';
import Tarefas from './tarefas';
import Novidades from './novidades';
import Tutoriais from './tutoriais';
import Empreendimentos from './empreendimentos';
import Arquitetos from './arquitetos';
import Empreendimento from './empreendimento';
import Construtoras from './construtoras';
import Imovel from './imovel';
import Cliente from './cliente';
import PipelineClientes from './pipeline-clientes';
import PipelineClientesLocacao from './pipeline-clientes-locacao';
import PipelineAlt from './pipeline-alternativas';
import Visitas from './visitas';
import Campanhas from './campanhas';
import Propostas from './propostas';
import Roleta from './roleta-de-leads';
import GerenciarArquiteto from './gerenciar-arquiteto';
import Email from './email';
import Unidades from './unidades';
import GerenciarImovel from './gerenciar-imovel';
import GerenciarEmpreendimento from './gerenciar-empreendimento';
import GerenciarConstrutora from './gerenciar-construtora';
import FeatherIcon from 'feather-icons-react';
import withAdminLayout from '../../layout/withAdminLayout';
import { database } from '../../firebase';
import firebase from 'firebase/app';
import Portais from './portais';
import Blog from './blogs';
import SmartCache from '../../indexeddb';
import { test_idb } from '../../indexeddb_tests';
import Error from '../../container/pages/404';
import Relatorios from './relatorios';
import Agenda from './agenda';
import CentralAnexos from './central-anexos';
import AppContext from '../../context';
// old
import { PushNotifications } from '@capacitor/push-notifications';
import { WorkerAction } from '../../WorkerController';
import { Corretor, Empresa } from 'smart-imob-types';
import createDoc from '../../utility/create_doc';
import { DateFromUnsafe, XisWhatPercentOfY } from '../../utility/util_functions';
import SuporteRoutes from './suporte';
import heic2any from 'heic2any';
import TawkTo from '../../utility/talk-to';
// Ativar essa config para exibir a porcentagem de itens processados pelos workers na interface.
// ---> É NECESSÁRIO ATIVAR OS HEADERS "COOP" E "COEP" NA HOSPEDAGEM DO FIREBASE <---
// Ref.: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/SharedArrayBuffer#security_requirements
// isCrossOriginIsolated = window.location.href.includes('localhost') || crossOriginIsolated
// @ts-ignore
const isCrossOriginIsolated = false;

if (isCrossOriginIsolated) {
  // SharedArrayBuffer utilizados para comunicar a quantidade de itens processados pelos workers
  window.sab_empreendimentos = new SharedArrayBuffer(8);
  window.sab_clientes = new SharedArrayBuffer(8);
  window.sab_imoveis = new SharedArrayBuffer(8);
  window.sab_visitas = new SharedArrayBuffer(8);
}
declare global {
  interface Window {
    sab_empreendimentos: SharedArrayBuffer;
    sab_clientes: SharedArrayBuffer;
    sab_imoveis: SharedArrayBuffer;
    sab_visitas: SharedArrayBuffer;
    WorkerAction: Function;
    test_idb: Function;
    ListaClienteTimeoutTxt: null | number;
    ListaImoveisTimeoutTxt: null | number;
  }
}

if (window.location.href.includes('debug')) {
  try {
    console.log = (f =>
      function log() {
        // @ts-ignore
        var ret = f.apply(this, arguments);
        let b = document.createElement('div');
        b.innerText = JSON.stringify(arguments);
        document.body.append(b);
        return ret;
      })(console.log);
  } catch (error) {}
}
// @ts-ignore expor a indexdb pra debuggar mais facilmente
window.SmartCache = SmartCache;
window.test_idb = test_idb;
window.WorkerAction = WorkerAction;

const Admin = React.memo(
  props => {
    const { path } = useRouteMatch();

    return (
      <Switch>
        <Suspense
          fallback={
            <div className="spin">
              <Spin />
            </div>
          }
        >
          <Route path={path} component={Dashboard} />
          <Route path={`${path}/dash-vendas`} component={DashVendas} />
          <Route path={`${path}/dash-locacao`} component={DashLocacao} />
          <Route path={`${path}/dash-corretor`} component={DashCorretor} />
          <Route path={`${path}/dash-temporada`} component={DashTemporada} />
          <Route path={`${path}/lista-de-imoveis`} component={ListaDeImoveis} />
          <Route
            path={`${path}/incluir-imovel`}
            component={(props: any) => <GerenciarImovel {...props} inclusao={true} />}
          />
          <Route
            path={`${path}/editar-imovel/:db_id`}
            component={(props: any) => (
              <GerenciarImovel {...props} imovel_id={props.match.params.db_id} edicao={true} />
            )}
          />
          <Route
            path={`${path}/incluir-arquiteto`}
            component={(props: any) => <GerenciarArquiteto {...props} inclusao={true} />}
          />
          <Route
            path={`${path}/editar-arquiteto/:db_id`}
            component={(props: any) => (
              <GerenciarArquiteto {...props} arquiteto_id={props.match.params.db_id} edicao={true} />
            )}
          />
          <Route
            path={`${path}/incluir-empreendimento`}
            component={(props: any) => <GerenciarEmpreendimento {...props} inclusao={true} />}
          />
          <Route
            path={`${path}/editar-empreendimento/:db_id`}
            component={(props: any) => (
              <GerenciarEmpreendimento {...props} empreendimento_id={props.match.params.db_id} edicao={true} />
            )}
          />
          <Route
            path={`${path}/incluir-construtora`}
            component={(props: any) => <GerenciarConstrutora {...props} inclusao={true} />}
          />
          <Route
            path={`${path}/editar-construtora/:db_id`}
            component={(props: any) => (
              <GerenciarConstrutora {...props} construtora_id={props.match.params.db_id} edicao={true} />
            )}
          />
          <Route path={`${path}/lista-de-clientes`} component={ListaDeClientes} />
          <Route path={`${path}/pipeline-de-clientes`} component={PipelineClientes} />
          <Route path={`${path}/pipeline-de-clientes-locacao`} component={PipelineClientesLocacao} />
          <Route path={`${path}/pipeline-alt`} component={PipelineAlt} />

          <Route path={`${path}/reservas`} component={Reservas} />
          <Route
            path={`${path}/incluir-cliente`}
            component={(props: any) => <ListaDeClientes {...props} startAdding />}
          />
          <Route path={`${path}/historico-de-atividades`} component={HistoricoAtividades} />
          <Route path={`${path}/equipe`} component={Equipe} />
          <Route path={`${path}/equipes`} component={Equipes} />
          <Route path={`${path}/cliente`} component={Cliente} />
          <Route path={`${path}/imovel`} component={Imovel} />
          <Route path={`${path}/novidades`} component={Novidades} />
          <Route path={`${path}/tutoriais`} component={Tutoriais} />
          <Route path={`${path}/configuracoes`} component={Configs} />
          <Route path={`${path}/empreendimento`} component={Empreendimento} />
          <Route path={`${path}/empreendimentos`} component={Empreendimentos} />
          <Route path={`${path}/arquitetos`} component={Arquitetos} />
          <Route path={`${path}/construtoras`} component={Construtoras} />
          <Route path={`${path}/email-marketing`} component={Email} />
          <Route path={`${path}/portais`} component={Portais} />
          <Route path={`${path}/blogs`} component={Blog} />
          <Route path={`${path}/visitas`} component={Visitas} />
          <Route path={`${path}/campanhas`} component={Campanhas} />
          <Route path={`${path}/propostas`} component={Propostas} />
          <Route path={`${path}/tarefas`} component={Tarefas} />
          <Route path={`${path}/roleta-de-leads`} component={Roleta} />
          <Route path={`${path}/relatorios`} component={Relatorios} />
          <Route path={`${path}/unidades`} component={Unidades} />
          <Route path={`${path}/suporte`} component={SuporteRoutes} />
          <Route path={`${path}/agenda`} component={Agenda} />
          <Route path={`${path}/central_anexos`} component={CentralAnexos} />
        </Suspense>
      </Switch>
    );
  },
  () => true,
);

/**
 * Estiliza a página e adiciona os componentes da volta (Menu lateral e topo)
 */
const AdminThemed = withAdminLayout((props: any) => <Admin {...props} />);
// variavel usada para mostrar notificações no mobile quando o app estiver em foco
let ultima_not_id: boolean | string = false;

function WrapWithAppContext() {
  const [Empresa, setEmpresa] = useState<Empresa | any>(null);
  const [InfoEmpresa, setInfoEmpresa] = useState<any>(null);
  const [User, setUser] = useState<Corretor | any | null>(null);

  const [LoadingInicial, setLoadingInicial] = useState(false);

  const [UsersNaEmpresa, setUsersNaEmpresa] = useState<any[]>([]);
  const [Equipes, setEquipes] = useState<any[]>([]);
  const [Unidades, setUnidades] = useState<any[]>([]);
  const [Notificacoes, setNotificacoes] = useState<any[]>([]);

  const [FotosParaFazerUpload, setFotosParaFazerUpload] = useState<FotoQueue[]>([]);

  useEffect(() => {
    (async () => {
      const foto_atual: FotoQueue = FotosParaFazerUpload[0];
      if (foto_atual) {
        const alvo = foto_atual.isEmpreendimento ? 'empreendimento' : 'imovel';
        const alvoPlural = foto_atual.isEmpreendimento ? 'empreendimentos' : 'imoveis';

        notification.open({
          key: foto_atual.db_id,
          message: 'Fazendo upload de fotos!',
          description: `Fazendo upload de ${
            FotosParaFazerUpload.filter(foto => foto.db_id === foto_atual.db_id).length
          } fotos do ${alvo} ${foto_atual.imovel}`,
          onClick: () => {},
          duration: 1000000,
          icon: <FeatherIcon icon="image" />,
        });

        try {
          const convertToJpeg = async base64String => {
            const response = await fetch(base64String);
            const blob = await response.blob();

            if (blob.type === 'image/heic' || blob.type === 'image/heif') {
              try {
                const convertedBlob: any = await heic2any({
                  blob,
                  toType: 'image/jpeg',
                  quality: 0.8,
                });
                return new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onloadend = () => resolve(reader.result);
                  reader.onerror = reject;
                  reader.readAsDataURL(convertedBlob);
                });
              } catch (conversionError) {
                console.error('Erro ao converter HEIC para JPEG:', conversionError);
                throw conversionError;
              }
            }
            return base64String;
          };

          let miniatura = foto_atual.miniatura;
          if (
            foto_atual.miniatura.startsWith('data:image/heic') ||
            foto_atual.miniatura.startsWith('data:image/heif')
          ) {
            miniatura = await convertToJpeg(foto_atual.miniatura);
          }

          let resized = foto_atual.resized;
          if (
            foto_atual.resized &&
            (foto_atual.resized.startsWith('data:image/heic') || foto_atual.resized.startsWith('data:image/heif'))
          ) {
            resized = await convertToJpeg(foto_atual.resized);
          }

          const getContentType = base64String => {
            if (base64String.startsWith('data:image/jpeg') || base64String.startsWith('data:image/jpg')) {
              return 'image/jpeg';
            }
            if (base64String.startsWith('data:image/png')) {
              return 'image/png';
            }
            return 'image/jpeg';
          };

          const main_contentType = getContentType(miniatura);
          const resized_contentType = getContentType(resized);

          const main_ref = firebase
            .storage()
            .ref(`empresas/${foto_atual.empresa}/${foto_atual.db_id}`)
            .child(foto_atual.id);
          const main_ref_res = await main_ref.putString(miniatura, 'data_url', { contentType: main_contentType });

          const resized_ref = firebase
            .storage()
            .ref(`empresas/${foto_atual.empresa}/${foto_atual.db_id}/resize`)
            .child(foto_atual.id);
          const resized_ref_res = await resized_ref.putString(resized, 'data_url', {
            contentType: resized_contentType,
          });

          const resized_url = await resized_ref_res.ref.getDownloadURL();
          const main_url = await main_ref_res.ref.getDownloadURL();

          const foto_to_add = {
            uploaded: true,
            source: {
              uri: main_url,
            },
            resized: resized_url,
            ordem: foto_atual.ordem,
            destaque: foto_atual.destaque,
            id: foto_atual.id,
          };

          const edited_at = new Date();
          const imovel_cache = await SmartCache[alvoPlural].getById(foto_atual.db_id);
          const fotos_to_replace = [...(imovel_cache?.fotos || []), foto_to_add].filter(
            (v, i, a) => !v.id || a.findIndex(t => t.id && t.id === v.id) === i,
          );

          await Promise.all([
            SmartCache[alvoPlural].updateById(foto_atual.db_id, {
              edited_at,
              fotos: fotos_to_replace,
            }),
            database.doc(`empresas/${foto_atual.empresa}/${alvoPlural}/${foto_atual.db_id}`).update({
              fotos: fotos_to_replace,
              edited_at,
            }),
          ]);

          await SmartCache.fotos_upload.deleteById(foto_atual.id);

          if (FotosParaFazerUpload.filter(foto => foto.empresa === foto_atual.empresa).length === 1) {
            notification.close(foto_atual.db_id);
            notification.info({
              message: `Fotos vinculadas!`,
              description: `Todas as fotos do ${alvo} #${foto_atual.imovel} foram vinculadas!`,
            });
          }
        } catch (error) {
          console.log('Erro ao fazer upload da foto:', error);
          notification.error({
            message: 'Erro no Upload',
            description: `Houve um erro ao fazer upload da foto ${foto_atual.imovel}. Por favor, tente novamente.`,
          });
        }

        console.log(foto_atual);
        setFotosParaFazerUpload(FotosParaFazerUpload.slice(1, Infinity));
      }
    })();
  }, [FotosParaFazerUpload]);

  const [SemEmpresa, setSemEmpresa] = useState(false);

  /**
   * Esse callback é chamado apenas uma vez, ele cria listeners para o usuário, a empresa, os usuarios da empresa e o documento info_empresas da empresa do usuario logado.
   * Após isso ele tenta executar todos os métodos de sincronização do banco para o indexeddb (alguns desses métodos estão acima),
   */
  useEffect(() => {
    let user_listener: any | Function;
    let empresa_listener: any | Function;
    let info_listener: any | Function;
    let notf_listener: any | Function;
    (async () => {
      const currentUser = firebase.auth().currentUser as firebase.User;

      database.doc(`users/${currentUser.uid}`).update({ ultimo_login: new Date() });
      const initial_user_ref = await database.doc(`users/${currentUser.uid}`).get();
      const user_data = initial_user_ref.data() as Corretor;

      if (!user_data.empresa) return setSemEmpresa(true);
      // Esse id vai se utilizado para pegar os arquitetos e construtoras quando for possivel
      // @ts-ignore
      global.soft_store_empresa_id = user_data.empresa;
      // @ts-ignore
      window.database = database;
      user_listener = database
        .doc(`users/${currentUser.uid}`)
        .onSnapshot(snap => setUser({ ...snap.data(), db_id: snap.id }));

      empresa_listener = database
        .doc(`empresas/${user_data.empresa}`)
        .onSnapshot(snap => setEmpresa({ ...snap.data(), db_id: snap.id }));

      info_listener = database
        .doc(`info_empresas/${user_data.empresa}`)
        .onSnapshot(snap => setInfoEmpresa(snap.data() || {}));

      notf_listener = database
        .collection(`users/${currentUser.uid}/notificacoes`)
        .orderBy('created_at', 'desc')
        .limit(5)
        .onSnapshot(snap => {
          if (snap.docs.length > 0 && ultima_not_id && ultima_not_id !== snap.docs[snap.docs.length - 1].id) {
            if (window.screen.width > 800) {
              message.info('Você recebeu uma nova notificação! Cheque sua caixa de notificações!');
            }
          }

          ultima_not_id = snap.docs[snap.docs.length - 1] ? snap.docs[snap.docs.length - 1].id : false;

          return setNotificacoes(snap.docs.map(createDoc));
        });
      database
        .collection(`empresas/${user_data.empresa}/equipes`)
        .onSnapshot(snap => setEquipes(snap.docs.map(createDoc)));

      database
        .collection(`empresas/${user_data.empresa}/unidades`)
        .onSnapshot(snap => setUnidades(snap.docs.map(createDoc)));

      database
        .collection(`users`)
        .where('empresa', '==', user_data.empresa)
        .onSnapshot(snap => setUsersNaEmpresa(snap.docs.map(createDoc)));

      // Chama todos ao mesmo tempo

      // Toda as coisa feia a gente passa pro web worker fazer
      try {
        if (user_data.empresa === '2GObPcOGelkJsFaqYs6B') {
          await WorkerAction(
            { fn: 'sync-imoveis', input: { sab: window.sab_imoveis, empresa_id: user_data.empresa } },
            true,
          );
          await WorkerAction(
            { fn: 'sync-clientes', input: { sab: window.sab_imoveis, empresa_id: user_data.empresa } },
            true,
          );
          await WorkerAction(
            { fn: 'sync-empreendimentos', input: { sab: window.sab_empreendimentos, empresa_id: user_data.empresa } },
            true,
          );
          await WorkerAction({ fn: 'sync-visitas', input: { sab: window.sab_visitas, empresa_id: user_data.empresa } });
        } else {
          const awz = await Promise.all([
            WorkerAction({
              fn: 'sync-imoveis',
              config: {
                debugPerformance: true,
              },
              input: {
                sab: window.sab_imoveis,
                empresa_id: user_data.empresa,
                // categoria: user_data.categoria
              },
            }),
            WorkerAction(
              {
                fn: 'sync-clientes',
                config: { debugPerformance: true },
                input: {
                  sab: window.sab_imoveis,
                  empresa_id: user_data.empresa,
                  // only_from_corretor_e_proprietarios: user_data.empresa === '41qY4Jle37OgdEVsvjOi' ? user_data.db_id : undefined,
                },
              },
              true,
            ),
            WorkerAction(
              {
                fn: 'sync-empreendimentos',
                config: { debugPerformance: true },
                input: { sab: window.sab_empreendimentos, empresa_id: user_data.empresa },
              },
              true,
            ),
            WorkerAction({
              fn: 'sync-visitas',
              config: { debugPerformance: true },
              input: { sab: window.sab_visitas, empresa_id: user_data.empresa },
            }),
          ]);
          console.log({ awz });
        }
      } catch (error) {
        console.log(error);
      }

      setLoadingInicial(true);
      // WorkerAction({ fn:'recommender-update' })

      // https://capacitorjs.com/docs/guides/push-notifications-firebase#integrating-firebase-with-the-android-app
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      try {
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
          permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
          console.log('notificacao cancelada');
        }

        await PushNotifications.register();

        PushNotifications.addListener('registration', token =>
          database
            .doc(`users/${currentUser.uid}`)
            .update({ push_token: token.value })
            .catch(console.error),
        );

        PushNotifications.addListener('registrationError', console.error);

        PushNotifications.addListener('pushNotificationReceived', notification =>
          console.log('pushNotificationReceived', notification),
        );

        PushNotifications.addListener('pushNotificationActionPerformed', notification =>
          console.log('pushNotificationActionPerformed', notification),
        );
      } catch (error) {
        console.error('NOTF ERROR', error);
      }
    })();
    return () => {
      user_listener();
      empresa_listener();
      info_listener();
      notf_listener();
    };
  }, []);

  // Verifica se tem o token na url e se tiver salva dento do user no firebase
  useEffect(() => {
    (async () => {
      const notificationParam = new URLSearchParams(window.location.search);
      const token = notificationParam.get('notification_token');
      if (token && User && User.notification_token != token) {
        await database.doc(`users/${User.db_id}`).update({ notification_token: token });
      }
    })();
  }, [User]);

  // Verifica se existe uma foto pendente para fazer o upload que não foi finalizada na sessão passada
  useEffect(() => {
    (async () => {
      const fotos_cache = await SmartCache.fotos_upload.getAll();
      setFotosParaFazerUpload([...FotosParaFazerUpload, ...fotos_cache]);
    })();
  }, []);

  // Metodo passado para o context para ativar o upload de fotos em paralelo
  const set_fotos_to_upload = async (fotos: FotoQueue[]) => {
    await SmartCache.fotos_upload.addMultiple(fotos);
    return setFotosParaFazerUpload([...FotosParaFazerUpload, ...fotos]);
  };

  // Atualiza a data do ultimo Login no info_empresa
  useEffect(() => {
    (async () => {
      if (User) {
        await database.doc(`info_empresas/${User.empresa}`).update({ ultimo_login: new Date() });
      }
    })();
  }, [User]);

  return (
    <AppContext.Provider
      value={{
        user: User,
        // @ts-ignore
        unidades: Unidades,
        empresa: Empresa,
        // @ts-ignore
        users_empresa: UsersNaEmpresa,
        // @ts-ignore
        equipes: Equipes,
        notificacoes: Notificacoes,
        info_empresa: InfoEmpresa,
        setInfoEmpresa: setInfoEmpresa,
        // @ts-ignore
        fotos_to_upload: FotosParaFazerUpload,
        set_fotos_to_upload: set_fotos_to_upload,
        /**
         * @deprecated
         */
        // @ts-ignore
        cache: SmartCache,
      }}
    >
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <TawkTo />

        {SemEmpresa && (
          <div style={{ height: '100vh', overflow: 'hidden' }}>
            <Error
              title="Você não está vinculado a nenhuma empresa! Entre em contato com o suporte para ser vinculado a outra empresa ou faça login com outra conta."
              btnText="Trocar de conta"
              // @ts-ignore
              clickBtn={() => firebase.auth().signOut()}
            />
          </div>
        )}
        {SemEmpresa ? null : User && Empresa && InfoEmpresa && LoadingInicial ? (
          <AdminThemed />
        ) : (
          <div
            className="spin"
            style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', padding: '10% 0', height: '95vh' }}
          >
            <img
              style={{ position: 'absolute', width: 72, maxHeight: 80 }}
              width="72"
              src={require('../../static/img/logo72.png')}
            />
            <div style={{ position: 'relative' }}>
              <ProgressCircleMock />
              <TextProgress />
            </div>
          </div>
        )}
      </Suspense>
    </AppContext.Provider>
  );
}

const TextProgress = React.memo(() => {
  // @ts-ignore
  if (!isCrossOriginIsolated) return null;

  const [Empreendimento_n_total, setEmpreendimento_n_total] = useState<null | number[]>(null);
  const [Imoveis_n_total, setImoveis_n_total] = useState<null | number[]>(null);
  const [Cliente_n_total, setCliente_n_total] = useState<null | number[]>(null);
  const [Visitas_n_total, setVisitas_n_total] = useState<null | number[]>(null);

  useEffect(() => {
    const uintarr_empreendimentos = new Uint32Array(window.sab_empreendimentos);
    const uintarr_clientes = new Uint32Array(window.sab_clientes);
    const uintarr_imoveis = new Uint32Array(window.sab_imoveis);
    const uintarr_visitas = new Uint32Array(window.sab_visitas);

    setEmpreendimento_n_total(Array.from(uintarr_empreendimentos));
    setImoveis_n_total(Array.from(uintarr_imoveis));
    setCliente_n_total(Array.from(uintarr_clientes));
    setVisitas_n_total(Array.from(uintarr_visitas));

    const interval_id = setInterval(() => {
      setEmpreendimento_n_total(Array.from(uintarr_empreendimentos));
      setImoveis_n_total(Array.from(uintarr_imoveis));
      setCliente_n_total(Array.from(uintarr_clientes));
      setVisitas_n_total(Array.from(uintarr_visitas));
    }, 100);
    return () => clearInterval(interval_id);
  }, []);

  if (!Empreendimento_n_total || !Imoveis_n_total || !Cliente_n_total || !Visitas_n_total) return null;

  const [Empreendimento_n, Empreendimento_total] = Empreendimento_n_total;
  const [Imoveis_n, Imoveis_total] = Imoveis_n_total;
  const [Cliente_n, Cliente_total] = Cliente_n_total;
  const [Visitas_n, Visitas_total] = Visitas_n_total;

  return (
    <div style={{ position: 'absolute', top: '100%' }}>
      <div>
        Imóveis: {Imoveis_total ? `(${Imoveis_n}/${Imoveis_total})` : ''}{' '}
        <Progress
          strokeColor={{
            '0%': '#ff8a00',
            '100%': '#FF69A5',
          }}
          percent={XisWhatPercentOfY(Imoveis_n, Imoveis_total || Infinity)}
          showInfo={false}
          status="active"
        />
      </div>
      <div>
        Empreendimentos: {Empreendimento_total ? `(${Empreendimento_n}/${Empreendimento_total})` : ''}{' '}
        <Progress
          strokeColor={{
            '0%': '#ff8a00',
            '100%': '#FF69A5',
          }}
          percent={XisWhatPercentOfY(Empreendimento_n || 0, Empreendimento_total || Infinity)}
          showInfo={false}
          status="active"
        />
      </div>
      <div>
        Clientes: {Cliente_total ? `(${Cliente_n}/${Cliente_total})` : ''}{' '}
        <Progress
          strokeColor={{
            '0%': '#ff8a00',
            '100%': '#FF69A5',
          }}
          percent={XisWhatPercentOfY(Cliente_n, Cliente_total || Infinity)}
          showInfo={false}
          status="active"
        />
      </div>
      <div>
        Visitas: {Visitas_total ? `(${Visitas_n}/${Visitas_total})` : ''}{' '}
        <Progress
          strokeColor={{
            '0%': '#ff8a00',
            '100%': '#FF69A5',
          }}
          percent={XisWhatPercentOfY(Visitas_n, Visitas_total || Infinity)}
          showInfo={false}
          status="active"
        />
      </div>
    </div>
  );
});

const ProgressCircleMock = React.memo((props: any) => {
  const [MockPercent, setMockPercent] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeout(() => {
        setMockPercent(m => (m < 90 ? m + 10 + Math.random() * 10 : m));
      }, Math.random() * 100);
    }, 300);

    const timeout2 = setTimeout(() => {
      clearInterval(timer);
      setMockPercent(100);
    }, 3000);
    return () => {
      clearInterval(timer);
      clearTimeout(timeout2);
    };
  }, []);

  return (
    <Progress
      type="circle"
      showInfo={false}
      strokeColor={{
        '0%': '#ff8a00',
        '100%': '#FF69A5',
      }}
      percent={MockPercent}
    />
  );
});

type FotoQueue = {
  isEmpreendimento?: boolean;
  db_id: string;
  empresa: string;
  miniatura: string;
  resized: string;
  ordem: number;
  destaque: boolean;
  imovel: string;
  id: string;
};

export default WrapWithAppContext;

/**
 * @ref https://stackoverflow.com/questions/6454198/check-if-a-value-is-within-a-range-of-numbers
 * @example
 * isInRange(5, 10, 2) // true
 * isInRange(5, 5, 2) // true // 5 É inclusivo
 * isInRange(5, 0, 2) // false
 * isInRange(5, 2, 12) // true
 */
const isInRange = (x, min, max) => {
  if (min > max) {
    // array swapping
    [max, min] = [min, max];
  }
  return x >= min && x <= max;
};

/**
 * @description Remove horarios e retorna a data em getTime()
 */
const normalizarDatas = (data: Date): number => {
  let new_date = new Date(data);
  new_date.setMinutes(0);
  new_date.setHours(0);
  new_date.setSeconds(0);
  new_date.setMilliseconds(0);
  return new_date.getTime();
};

const RangeInRange = (
  rangeA: { data_inicio: Date; data_fim: Date },
  rangeB: { data_inicio: Date; data_fim: Date },
  debug_id: string,
) => {
  // Input do usuário
  const numberRangeA = {
    data_inicio: normalizarDatas(rangeA.data_inicio),
    data_fim: normalizarDatas(rangeA.data_fim),
  };

  const numberRangeB = {
    data_inicio: normalizarDatas(rangeB.data_inicio),
    data_fim: normalizarDatas(rangeB.data_fim),
  };

  let debug_info: string[] = [];
  let res;
  if (isInRange(numberRangeA.data_inicio, numberRangeB.data_inicio, numberRangeB.data_fim)) {
    debug_info.push('numberRangeA.data_inicio in numberRangeB');
    res = true;
  }
  if (isInRange(numberRangeA.data_fim, numberRangeB.data_inicio, numberRangeB.data_fim)) {
    debug_info.push('numberRangeA.data_fim in numberRangeB');
    res = true;
  }

  if (res === undefined) {
    res = false;
  }

  if (debug_id) {
    console.log(`
    --------------------
      debug_id: ${debug_id}

      numberRangeA
        - data_inicio: ${numberRangeA.data_inicio}
        - data_fim:${numberRangeA.data_fim}

      numberRangeB
        - data_inicio: ${numberRangeB.data_inicio}
        - data_fim:${numberRangeB.data_fim}

      debug_info: ${debug_info.join(' --- ')}

      return RangeInRange(numberRangeA, numberRangeB) === ${res}
    `);
  }
  return res;
};

// @ts-ignore
window.RangeInRange = RangeInRange;
