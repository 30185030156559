import { useContext, useEffect } from 'react';
import AppContext from '../context';

function TawkTo() {
  const context = useContext(AppContext);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://embed.tawk.to/67b7885d784e26190d3af7e8/1ikiepb7j';
    script.async = true;
    script.charset = 'UTF-8';

    script.onload = () => {
      try {
        window.Tawk_API?.onLoad?.();

        window.Tawk_API?.setAttributes?.(
          {
            name: context.empresa?.nome ??  context.user?.nome ?? 'Visitante',
            email: context.user?.email ?? 'sem-email',
            hash: context.user?.db_id ?? '',
          },
          error => {
            if (error) {
              console.error('Erro ao configurar atributos do Tawk.to:', error);
            } else {
              console.log('Atributos do Tawk.to configurados com sucesso!');
            }
          },
        );
      } catch (err) {
        console.error('Erro ao configurar o Tawk.to:', err);
      }
    };

    script.onerror = (err) => {
      console.error('Erro ao carregar script do Tawk.to:', err);
    };

    document.body.appendChild(script);

    return () => {
      try {
        document.body.removeChild(script);
      } catch (err) {
        console.error('Erro ao remover script do Tawk.to:', err);
      }
    };
  }, [context.empresa?.nome, context.user?.email, context.user?.db_id]);

  return null;
}

export default TawkTo;
